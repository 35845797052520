'use client'
import React from "react";
import AppSlider from "@/app/components/atomic/AppSlider";
import {IAppliedFilter, ISliderFilterConfig} from "@/app/models/auctor";

export default function FilterSlider({filterConfig, appliedFilter, handleChange}: {
    filterConfig: ISliderFilterConfig,
    appliedFilter: IAppliedFilter,
    handleChange: any
}) {
    const onChange = (newValue: any) => {
        handleChange(appliedFilter, newValue);
    }

    // @ts-ignore
    const parsedValue = parseFloat(appliedFilter.value).toLocaleString('fr-FR');
    return (
        <div
            className="w-full flex flex-col justify-center gap-1"
        >
            <span className="font-semibold text-blue-600">
                {parsedValue ?? 'N/A'} { filterConfig.suffix }
            </span>
            <AppSlider
                // @ts-ignore
                minValue={filterConfig.min_slider_value}

                // @ts-ignore
                maxValue={filterConfig.max_slider_value}
                // @ts-ignore
                value={appliedFilter.value}
                // @ts-ignore
                step={filterConfig.slider_step}
                showMarkers={true}
                showMedianValue={filterConfig.show_median_value ?? false}
                handleChange={onChange}
            />
        </div>
    )
}
