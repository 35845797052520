import AppInput, {GenericInputReturn} from "@/app/components/atomic/Input/AppInput";
import {IAppliedFilter, INumberFilterConfig} from "@/app/models/auctor";

export default function FilterNumber({filterConfig, appliedFilter, handleChange}: {
    filterConfig: INumberFilterConfig,
    appliedFilter: IAppliedFilter,
    handleChange: any
}) {
    const onChange = (inputReturn: GenericInputReturn) => {
        handleChange(appliedFilter, inputReturn.value);
    }

    return (
        <>
            <AppInput
                type="number"
                onChange={onChange}
                value={appliedFilter.value ?? ''}
                addonContent={filterConfig.addon_content}
                fieldName={filterConfig.variable_name as string}
                min={filterConfig.min_filter_value}
                max={filterConfig.max_filter_value}
                debounce={100}
            />
        </>
    )
}
