'use client'
import dynamic from 'next/dynamic';
import React from "react";
import {IAppliedFilter, IDateFilterConfig} from "@/app/models/auctor";

const AppDatePicker = dynamic(() => import('@/app/components/atomic/molecules/AppDatePicker'), {
    ssr: false,
});

export default function FilterDate({filterConfig, appliedFilter, handleChange}: {
    filterConfig: IDateFilterConfig,
    appliedFilter: IAppliedFilter,
    handleChange: any
}) {
    const onChange = (newValue: any) => {
        handleChange(appliedFilter, newValue);
    }

    return (
        <AppDatePicker
            value={appliedFilter.value}
            minDate={filterConfig.min_filter_date}
            maxDate={filterConfig.max_filter_date}
            onChange={onChange}
            displayIcon
        />
    )
}
