import React from 'react';

interface AppSwitchProps {
    left: {
        label?: string;
        value: any;
    };
    right: {
        label?: string;
        value: any;
    };
    storedValue: any;
    onUpdate: (value: any) => void;
}

function AppSwitch(props: AppSwitchProps) {
    const isLeftValueSelected = props.storedValue === props.left.value;

    function toggleValue() {
        const newValue = isLeftValueSelected ? props.right.value : props.left.value;
        props.onUpdate(newValue);
    }

    return (
        <div className="flex gap-2 items-center justify-center cursor-pointer select-none items-center" onClick={toggleValue}>
            <div className="mr-2 flex items-center justify-end">
                <span
                    className={`text-start text-blue-700 font-light transition-all duration-300
                    ${isLeftValueSelected ? 'opacity-100 font-normal' : 'opacity-50'}`}
                >
                    {props.left.label}
                </span>
            </div>

            <div className="flex items-center justify-center">
                <div
                    className={`rounded-full relative transition-all duration-300
                w-12 h-6 ${isLeftValueSelected ? 'bg-blue-300' : 'bg-blue-700'}`}
                >
                    <div
                        className={`absolute rounded-full bg-white transition-all duration-300
                    h-5 w-5 top-[0.145rem] ${isLeftValueSelected ? 'left-[0.145rem]' : 'left-[1.625rem]'}`}
                    ></div>
                </div>
            </div>

            <div className="ml-2 flex items-center justify-start">
                <span
                    className={`text-end text-blue-700 font-light transition-all duration-300
                    ${isLeftValueSelected ? 'opacity-50' : 'opacity-100 font-normal'}`}
                >{props.right.label}</span>
            </div>
        </div>
    );
}

export default AppSwitch;
