import {IAppliedFilter, ISelectFilterConfig} from "@/app/models/auctor";
import '@/app/styles/auctor/FilterCountry.scss';
import {useEffect, useState} from "react";
import {SelectFilterSkeleton} from "@/app/components/auctor/filter/FilterSelect";
import dynamic from 'next/dynamic';

const ReactFlagsSelect = dynamic(() => import('react-flags-select'), {
    ssr: false,
});

export default function FilterCountrySelect({filterConfig, appliedFilter, handleChange}: {
    filterConfig: ISelectFilterConfig,
    appliedFilter: IAppliedFilter,
    handleChange: any
}) {
    const onSelect = (newValue: any) => {
        handleChange(appliedFilter, newValue);
    }
    
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => setIsMounted(true), []);

    const customLabels = {
        "AF": "Afghanistan",
        "AL": "Albanie",
        "DZ": "Algérie",
        "AS": "Samoa américaines",
        "AD": "Andorre",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctique",
        "AG": "Antigua-et-Barbuda",
        "AR": "Argentine",
        "AM": "Arménie",
        "AW": "Aruba",
        "AU": "Australie",
        "AT": "Autriche",
        "AZ": "Azerbaïdjan",
        "BS": "Bahamas",
        "BH": "Bahreïn",
        "BD": "Bangladesh",
        "BB": "Barbade",
        "BY": "Biélorussie",
        "BE": "Belgique",
        "BZ": "Belize",
        "BJ": "Bénin",
        "BM": "Bermudes",
        "BT": "Bhoutan",
        "BO": "Bolivie",
        "BA": "Bosnie-Herzégovine",
        "BW": "Botswana",
        "BR": "Brésil",
        "IO": "Territoire britannique de l'océan Indien",
        "VG": "Îles Vierges britanniques",
        "BN": "Brunei",
        "BG": "Bulgarie",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Cambodge",
        "CM": "Cameroun",
        "CA": "Canada",
        "CV": "Cap-Vert",
        "KY": "Îles Caïmans",
        "CF": "République centrafricaine",
        "TD": "Tchad",
        "CL": "Chili",
        "CN": "Chine",
        "CX": "Île Christmas",
        "CC": "Îles Cocos",
        "CO": "Colombie",
        "KM": "Comores",
        "CD": "République démocratique du Congo",
        "CG": "République du Congo",
        "CK": "Îles Cook",
        "CR": "Costa Rica",
        "CI": "Côte d'Ivoire",
        "HR": "Croatie",
        "CU": "Cuba",
        "CW": "Curaçao",
        "CY": "Chypre",
        "CZ": "République tchèque",
        "DK": "Danemark",
        "DJ": "Djibouti",
        "DM": "Dominique",
        "DO": "République dominicaine",
        "EC": "Équateur",
        "EG": "Égypte",
        "SV": "El Salvador",
        "GQ": "Guinée équatoriale",
        "ER": "Érythrée",
        "EE": "Estonie",
        "ET": "Éthiopie",
        "FK": "Îles Malouines",
        "FO": "Îles Féroé",
        "FJ": "Fidji",
        "FI": "Finlande",
        "FR": "France",
        "GF": "Guyane française",
        "PF": "Polynésie française",
        "GA": "Gabon",
        "GM": "Gambie",
        "GE": "Géorgie",
        "DE": "Allemagne",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Grèce",
        "GL": "Groenland",
        "GD": "Grenade",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernesey",
        "GN": "Guinée",
        "GW": "Guinée-Bissau",
        "GY": "Guyana",
        "HT": "Haïti",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hongrie",
        "IS": "Islande",
        "IN": "Inde",
        "ID": "Indonésie",
        "IR": "Iran",
        "IQ": "Irak",
        "IE": "Irlande",
        "IM": "Île de Man",
        "IL": "Israël",
        "IT": "Italie",
        "JM": "Jamaïque",
        "JP": "Japon",
        "JE": "Jersey",
        "JO": "Jordanie",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "KW": "Koweït",
        "KG": "Kirghizistan",
        "LA": "Laos",
        "LV": "Lettonie",
        "LB": "Liban",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libye",
        "LI": "Liechtenstein",
        "LT": "Lituanie",
        "LU": "Luxembourg",
        "MO": "Macao",
        "MK": "Macédoine",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaisie",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malte",
        "MH": "Îles Marshall",
        "MR": "Mauritanie",
        "MU": "Maurice",
        "YT": "Mayotte",
        "MX": "Mexique",
        "FM": "Micronésie",
        "MD": "Moldavie",
        "MC": "Monaco",
        "MN": "Mongolie",
        "ME": "Monténégro",
        "MS": "Montserrat",
        "MA": "Maroc",
        "MZ": "Mozambique",
        "MM": "Myanmar",
        "NA": "Namibie",
        "NR": "Nauru",
        "NP": "Népal",
        "NL": "Pays-Bas",
        "NC": "Nouvelle-Calédonie",
        "NZ": "Nouvelle-Zélande",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Île Norfolk",
        "KP": "Corée du Nord",
        "MP": "Îles Mariannes du Nord",
        "NO": "Norvège",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palaos",
        "PS": "Palestine",
        "PA": "Panama",
        "PG": "Papouasie-Nouvelle-Guinée",
        "PY": "Paraguay",
        "PE": "Pérou",
        "PH": "Philippines",
        "PN": "Îles Pitcairn",
        "PL": "Pologne",
        "PT": "Portugal",
        "PR": "Porto Rico",
        "QA": "Qatar",
        "RE": "La Réunion",
        "RO": "Roumanie",
        "RU": "Russie",
        "RW": "Rwanda",
        "BL": "Saint-Barthélemy",
        "SH": "Sainte-Hélène",
        "KN": "Saint-Christophe-et-Niévès",
        "LC": "Sainte-Lucie",
        "MF": "Saint-Martin",
        "PM": "Saint-Pierre-et-Miquelon",
        "VC": "Saint-Vincent-et-les-Grenadines",
        "WS": "Samoa",
        "SM": "Saint-Marin",
        "ST": "Sao Tomé-et-Principe",
        "SA": "Arabie saoudite",
        "SN": "Sénégal",
        "RS": "Serbie",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapour",
        "SX": "Saint-Martin (Pays-Bas)",
        "SK": "Slovaquie",
        "SI": "Slovénie",
        "SB": "Îles Salomon",
        "SO": "Somalie",
        "ZA": "Afrique du Sud",
        "KR": "Corée du Sud",
        "SS": "Soudan du Sud",
        "ES": "Espagne",
        "LK": "Sri Lanka",
        "SD": "Soudan",
        "SR": "Suriname",
        "SZ": "Eswatini",
        "SE": "Suède",
        "CH": "Suisse",
        "SY": "Syrie",
        "TW": "Taïwan",
        "TJ": "Tadjikistan",
        "TZ": "Tanzanie",
        "TH": "Thaïlande",
        "TL": "Timor oriental",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinité-et-Tobago",
        "TN": "Tunisie",
        "TR": "Turquie",
        "TM": "Turkménistan",
        "TC": "Îles Turques-et-Caïques",
        "TV": "Tuvalu",
        "UG": "Ouganda",
        "UA": "Ukraine",
        "AE": "Émirats arabes unis",
        "GB": "Royaume-Uni",
        "US": "États-Unis",
        "UY": "Uruguay",
        "UZ": "Ouzbékistan",
        "VU": "Vanuatu",
        "VA": "Vatican",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "WF": "Wallis-et-Futuna",
        "EH": "Sahara occidental",
        "YE": "Yémen",
        "ZM": "Zambie",
        "ZW": "Zimbabwe"
    };

    return isMounted ? (
        <ReactFlagsSelect
            customLabels={customLabels}
            selected={appliedFilter.value}
            searchPlaceholder={'rechercher'}
            searchable
            className={'w-full text-blue-900 cursor-pointer'}
            selectButtonClassName={'focus-within:border-blue-500 hover:border-blue-500 shadow-none h-12 border-blue-300 transition-all duration-500'}
            onSelect={onSelect}
            placeholder={'Sélectionnez votre pays...'}
        />
    ) : <SelectFilterSkeleton />
}
