import AppSwitch from "@/app/components/atomic/AppSwitch";
import {IAppliedFilter, IToggleFilterConfig} from "@/app/models/auctor";

export default function FilterToggle({filterConfig, appliedFilter, handleChange}: { filterConfig: IToggleFilterConfig, appliedFilter: IAppliedFilter, handleChange: any }) {
    const onChange = (newValue: any) => {
        handleChange(appliedFilter, newValue);
    }

    return(
        <>
            <AppSwitch
                left={{label: filterConfig.left_label, value: filterConfig.left_value}}
                right={{label: filterConfig.right_label, value: filterConfig.right_value}}
                storedValue={appliedFilter.value ?? ''}
                onUpdate={onChange}
            />
        </>
    )
}
