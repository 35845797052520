interface Props {
    minValue: number;
    maxValue: number;
    value: number | null;
    step: number;
    showMarkers: boolean;
    showMedianValue: boolean;
    handleChange: any;
}

export default function AppSlider({minValue, maxValue, value, step, showMarkers, showMedianValue, handleChange}: Props) {

    const markers = [
        minValue,
        maxValue
    ];

    if (showMedianValue) {
        const medianValue = minValue + (maxValue - minValue) / 2;
        markers.splice(1, 0, medianValue);
    }

    const parsedValue = value === null ? minValue : value;
    const fillerWidth = `${((parsedValue - minValue) / (maxValue - minValue)) * 100}%`;

    const onChange = (event: any) => {
        handleChange(parseFloat(event.target.value));
    }

    return (
        <div className="flex flex-col w-full relative">
            <input
                type="range"
                min={minValue}
                max={maxValue}
                value={parsedValue}
                step={step}
                onInput={onChange}
                className={`
                    w-full cursor-pointer
                    appearance-none bg-transparent
                    [&::-webkit-slider-runnable-track]:rounded-full
                    [&::-webkit-slider-runnable-track]:border-none
                    [&::-webkit-slider-runnable-track]:bg-blue-300
                    [&::-webkit-slider-runnable-track]:h-2
                    [&::-webkit-slider-thumb]:appearance-none
                    [&::-webkit-slider-thumb]:relative
                    [&::-webkit-slider-thumb]:-mt-1
                    [&::-webkit-slider-thumb]:h-4
                    [&::-webkit-slider-thumb]:w-4
                    [&::-webkit-slider-thumb]:rounded-full
                    [&::-webkit-slider-thumb]:bg-blue-600
                    [&::-webkit-slider-thumb]:z-10
                    [&::-webkit-slider-thumb]:pointer-events-none
                    [&::-webkit-slider-thumb]:border
                    [&::-webkit-slider-thumb]:border-blue-300
                    relative after:content-[''] after:absolute after:bottom-[-100%] after:left-0 after:w-full after:h-[25px] after:bg-transparent
                `}
            />

            <div
                className="absolute left-0 h-2 bg-blue-600 rounded-full pointer-events-none"
                style={{width: fillerWidth}}
            />

            {showMarkers && (
                <datalist id="markers" className="flex justify-between w-full text-xs text-blue-900 font-semibold mt-2 px-4">
                    {markers.map(marker => (
                        <option
                            key={marker}
                            value={marker}
                            className={`font-semibold w-2 flex items-center justify-center ${marker > parsedValue ? 'text-blue-900' : 'text-blue-600'}`}
                        >
                            {marker.toLocaleString('fr-FR')}
                        </option>
                    ))}
                </datalist>
            )}

        </div>
    );
}
